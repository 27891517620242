import React from 'react';

type Props = {
  className?: string;
};

export const LogoText = React.forwardRef<SVGSVGElement, Props>(
  ({ className }, ref) => (
    <svg
      ref={ref}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 506.2 79.8"
      xmlSpace="preserve"
      className={`fill-current ${className ?? ''}`}
    >
      <path d="M39.9 0C17.9 0 0 17.9 0 39.9s17.9 39.9 39.9 39.9S79.8 62 79.8 39.9 62 0 39.9 0zm19.3 52.5c.1-.7.2-1.4.2-2.1 0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5 4.7 10.5 10.5 10.5h.7c-2.9 1.4-6.2 2.1-9.7 2.1-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23c.1 4.6-1.3 8.9-3.7 12.5zM88.5 50.7V1.3h16.2V49c0 10 4.9 16.2 15 16.2 10.6 0 14.5-6.9 14.5-16.2V1.3h16.2V50c0 17.5-10.4 29.8-31 29.8-19 0-30.9-10.3-30.9-29.1zM211.3 78.5l-5.9-15.7h-28l-5.9 15.7h-16.6l29.3-77.2H199l29.5 77.2h-17.2zm-28.7-30.1H200l-8.7-23.3-8.7 23.3zM284.5 1.3h16.2v77.2h-16.4l-32.5-49.9v49.9h-16.2V1.3H255l29.5 46.2V1.3zM368.1 1.3v14.5h-20.5v62.6h-16.2V15.9h-20.8V1.3h57.5zM371.8 55.4l16.2-3.6c1.3 8.4 6.5 14 16.6 14 8.1 0 12.9-3.5 12.9-9.1 0-14.5-43.2-5.8-43.2-34C374.4 8.3 385.6 0 403.9 0c14.9 0 26.3 7.5 28.5 19.9l-15.1 4.8c-1.8-6.2-6.1-11-14.3-11-7.2 0-12.1 2.6-12.1 7.4 0 14 43.2 4.7 43.2 34.8 0 14.6-10.1 23.9-29.5 23.9-17.6 0-30.8-8.2-32.8-24.4zM488.1 1.3h18l-28.4 46.1v31h-16.2v-31L433.2 1.3h19.2L470 31.1l18.1-29.8z" />
    </svg>
  ),
);

LogoText.displayName = 'LogoText';
