import useSWR from 'swr';

export type GroupedApiAccountsRequest = {
  date: string;
  sum: number;
};

export const useDateGroupedApiAccountsRequests = () => {
  const { data, error, mutate } = useSWR<GroupedApiAccountsRequest[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/date_grouped_api_accounts_requests`,
  );

  return {
    data,
    error,
    mutate,
  };
};
