import { formatDistanceToNow } from 'date-fns';

import { Table, Tbody, Thead } from '@/components/Table';
import { useApiAccountsRequests } from '@/components/useApiAccountsRequests';
import { TransparentButton } from '@/components/TransparentButton';
import { NoDataText } from '@/components/NoDataText';

export const ApiAccountsRequestsTable: React.FC = () => {
  const { data, error, page, totalPages, next, prev } =
    useApiAccountsRequests();

  return (
    <>
      {error ? (
        <NoDataText spacer>
          There was an error fetching API requests.
        </NoDataText>
      ) : data ? (
        data.data.length === 0 ? (
          <NoDataText spacer>No API requests have been issued yet.</NoDataText>
        ) : (
          <Table>
            <Thead.Thead>
              <Thead.Tr>
                <Thead.Th>key</Thead.Th>
                <Thead.Th className="hidden lg:table-cell">uri</Thead.Th>
                <Thead.Th>cu impact</Thead.Th>
                <Thead.Th>created</Thead.Th>
              </Thead.Tr>
            </Thead.Thead>
            <Tbody.Tbody>
              {data.data.map((key, idx) => (
                <Tbody.Tr key={idx}>
                  <Tbody.Td>{key.api_key_name}</Tbody.Td>
                  <Tbody.Td className="hidden lg:table-cell">
                    {key.request_url}
                  </Tbody.Td>
                  <Tbody.Td>{key.cu_quota_impact}</Tbody.Td>
                  <Tbody.Td>
                    {formatDistanceToNow(new Date(key.created_at))} ago
                  </Tbody.Td>
                </Tbody.Tr>
              ))}
            </Tbody.Tbody>
          </Table>
        )
      ) : (
        <NoDataText spacer>Loading...</NoDataText>
      )}

      {data && data.data.length !== 0 && (
        <div className="uppercase text-xs mt-4">
          <span className="font-bold">
            page {page + 1} of {totalPages}
          </span>
          <div className="flex gap-x-2 mt-2">
            <TransparentButton compact onClick={prev} disabled={page === 0}>
              PREV
            </TransparentButton>
            <TransparentButton
              compact
              onClick={next}
              disabled={page + 1 === totalPages}
            >
              NEXT
            </TransparentButton>
          </div>
        </div>
      )}
    </>
  );
};
