import useSWR from 'swr';

type Account = {
  id: number;
  email: string;
  email_verified: boolean;
  first_name: string;
  last_name: string;
  api_subscription_id: number;
  pending_api_subscription_id: number | null;
  current_cycle_cu_quota_usage: number;
  cu_quota_grant: number;
  subscription_name: string;
  api_key_quota_grant: number;
  stripe_payment_method_id: string | null;
  current_period_start: string | null;
  current_period_end: string | null;
  subscription_status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';
};

export const useAccount = () => {
  const {
    data: account,
    error,
    mutate,
  } = useSWR<Account>(`${process.env.NEXT_PUBLIC_API_URL}/account`);

  return { account, accountError: error, accountMutate: mutate };
};
