import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR, { Key } from 'swr';

import { PaginatedResponse } from '@/util';

type Props<T> = {
  limit: number;
  query?: URLSearchParams;
};

export const usePaginatedSWR = <T>(
  key: Key,
  { limit, query: queryRef }: Props<T>,
) => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>();
  const offset = page * limit;
  const query = new URLSearchParams(queryRef);
  query.set('limit', limit.toString());
  query.set('offset', offset.toString());

  const { data, error, mutate } = useSWR<PaginatedResponse<T>>(
    `${key}?${query.toString()}`,
  );

  useEffect(() => {
    if (data && data.total !== total) {
      setTotal(data.total);
    }
  }, [key, data, total]);

  const totalPages = useMemo(
    () => (total ? Math.ceil(total / limit) : undefined),
    [limit, total],
  );

  const prev = useCallback(() => {
    setPage(curPage => (curPage === 0 ? curPage : curPage - 1));
  }, []);

  const next = useCallback(() => {
    setPage(curPage => (curPage + 1 === totalPages ? curPage : curPage + 1));
  }, [totalPages]);

  const first = useCallback(() => {
    setPage(0);
  }, []);

  const last = useCallback(
    () => (totalPages ? setPage(totalPages) : undefined),
    [totalPages],
  );

  return {
    data,
    error,
    mutate,
    prev,
    next,
    first,
    last,
    totalPages,
    page,
    offset,
    setPage,
  };
};
