type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { spacer?: boolean };

export function NoDataText({ className, spacer, ...props }: Props) {
  return (
    <div
      className={`text-gray-900 uppercase text-2xs text-center tracking-wide ${
        spacer ? 'py-20' : ''
      } ${className ?? ''}`}
      {...props}
    />
  );
}
