import { useEffect, useState } from 'react';

const cookieMatch = /r=1/;
const hasCookie = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return cookieMatch.test(window.document.cookie);
};

const raf =
  typeof requestIdleCallback !== 'undefined'
    ? requestIdleCallback
    : typeof requestAnimationFrame !== 'undefined'
    ? requestAnimationFrame
    : (f: () => void) => setTimeout(f, 0);

export const useSessionIsActive = () => {
  const [isActive, setIsActive] = useState(hasCookie());

  useEffect(() => {
    const interval = setInterval(
      () =>
        raf!(() => {
          if (hasCookie()) {
            if (!isActive) {
              setIsActive(true);
            }
          } else {
            if (isActive) {
              setIsActive(false);
            }
          }
        }),
      100,
    );

    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  return isActive;
};
