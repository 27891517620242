import { useEffect, useMemo, useRef } from 'react';
import {
  ChartOptions,
  createChart,
  DeepPartial,
  IChartApi,
  PriceFormat,
} from 'lightweight-charts';
import format from 'date-fns/format';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import { useDateGroupedApiAccountsRequests } from '@/components/useDateGroupedApiAccountsRequests';
import { useAccount } from '@/components/useAccount';
import { GradientBorder } from '@/components/GradientBorder';

const dateFormat = 'yyyy-LL-dd';

type SeriesData = {
  value?: number;
  time: string;
};

function resetDate(date: Date) {
  const d = new Date(date);

  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
}

function Swatch({ color }: { color: string }) {
  return (
    <div className="rounded-full w-3 h-3" style={{ backgroundColor: color }} />
  );
}

function LegendItem({
  color,
  children,
}: {
  color: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex items-center gap-x-2">
      <Swatch color={color} />
      {children}
    </div>
  );
}

const chartOptions: DeepPartial<ChartOptions> = {
  layout: {
    background: { color: '#0a0909' },
    textColor: '#fff',
    fontFamily: '"ES Allianz Extra Bold", sans-serif',
  },
  grid: {
    horzLines: { color: 'rgba(50,50,50,0.3)' },
    vertLines: { visible: false },
  },
  autoSize: true,
  handleScroll: false,
  handleScale: false,
  crosshair: {
    vertLine: { visible: false },
    horzLine: { visible: false },
  },
  leftPriceScale: {
    visible: true,
    borderVisible: false,
    scaleMargins: {
      top: 0.05,
      bottom: 0.05,
    },
  },
  rightPriceScale: {
    visible: false,
  },
  timeScale: {
    lockVisibleTimeRangeOnResize: true,
    fixLeftEdge: true,
    fixRightEdge: true,
    borderVisible: false,
  },
};
const priceFormat: Partial<PriceFormat> = {
  type: 'volume',
  minMove: 1,
};
const allottedLineOptions: Parameters<IChartApi['addLineSeries']>[0] = {
  color: '#6b7993',
  lineWidth: 1,
  lastValueVisible: false,
  priceLineVisible: false,
  priceFormat,
};
const predictedLineOptions: Parameters<IChartApi['addLineSeries']>[0] = {
  color: '#252e3e',
  lineWidth: 1,
  lastValueVisible: false,
  priceLineVisible: false,
  priceFormat,
};
const usedAreaOptions: Parameters<IChartApi['addAreaSeries']>[0] = {
  lineColor: '#007bff',
  topColor: 'rgba(72,198,239,0.08)',
  bottomColor: 'rgba(111,134,214,0.08)',
  lineWidth: 3,
  lastValueVisible: false,
  priceLineVisible: false,
  priceFormat,
};

export function DateGroupedApiAccountsRequestsChart() {
  const { data, error } = useDateGroupedApiAccountsRequests();
  const { account, accountError } = useAccount();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const cycleDayIterator = useMemo(() => {
    if (!account?.current_period_end || !account?.current_period_start) {
      return undefined;
    }

    return eachDayOfInterval({
      start: new Date(account.current_period_start),
      end: new Date(account.current_period_end),
    });
  }, [account?.current_period_start, account?.current_period_end]);

  const cuQuotaGrant = useMemo(
    () => account?.cu_quota_grant,
    [account?.cu_quota_grant],
  );

  const dailyAvg = useMemo(
    () =>
      data ? data.reduce((sum, item) => sum + item.sum, 0) / data.length : 0,
    [data],
  );

  useEffect(() => {
    if (!containerRef.current || !data || !cycleDayIterator || !cuQuotaGrant) {
      return;
    }

    const planAllotedAvg = cuQuotaGrant / cycleDayIterator.length;

    const totalData = cycleDayIterator.reduce(
      (prev, date, index) => {
        const time = format(date, dateFormat);

        if (prev.allotted.length === 0) {
          prev.allotted.push({ time, value: planAllotedAvg });
          prev.predicted.push({ time, value: dailyAvg });
          prev.used.push({ time, value: data[index] ? data[index].sum : 0 });
        } else {
          const prevAllotted = prev.allotted[index - 1];
          const prevPredicted = prev.predicted[index - 1];
          const prevUsed = prev.used[index - 1];

          const planAllotedSum = prevAllotted.value! + planAllotedAvg;
          const predictedSum = prevPredicted.value! + dailyAvg;
          const usedDataValue = data[index];

          prev.allotted.push({
            time,
            value:
              index === cycleDayIterator.length - 1
                ? Math.round(planAllotedSum)
                : planAllotedSum,
          });
          prev.predicted.push({
            time,
            value:
              index === cycleDayIterator.length - 1
                ? Math.round(predictedSum)
                : predictedSum,
          });

          prev.used.push({
            time,
            value: usedDataValue
              ? usedDataValue.sum + prevUsed.value!
              : undefined,
          });
        }

        return prev;
      },
      { allotted: [], predicted: [], used: [] } as {
        allotted: SeriesData[];
        predicted: SeriesData[];
        used: SeriesData[];
      },
    );

    const chart = createChart(containerRef.current, chartOptions);
    const allottedSeries = chart.addLineSeries(allottedLineOptions);
    const predictedSeries = chart.addLineSeries(predictedLineOptions);
    const usedSeries = chart.addAreaSeries(usedAreaOptions);
    allottedSeries.setData(totalData.allotted);
    predictedSeries.setData(totalData.predicted);
    usedSeries.setData(totalData.used);
    chart.timeScale().fitContent();

    return () => {
      chart.remove();
    };
  }, [containerRef, data, cuQuotaGrant, cycleDayIterator, dailyAvg]);

  if (!data || !account || !cycleDayIterator) {
    return null;
  }

  if (error || accountError) {
    return <div>There was an error loading requests.</div>;
  }

  const predictedUsage = Math.round(dailyAvg * cycleDayIterator.length);

  return (
    <GradientBorder>
      <div
        ref={containerRef}
        className="h-[35vh] lg:h-[60vh] pr-7 py-4 lg:pl-4 lg:pr-11 lg:py-8"
      />
      <div className="flex flex-col gap-y-2 pb-4 lg:flex-row items-center gap-x-8 font-bold lg:px-11 lg:pb-8 uppercase text-2xs lg:text-xs text-gray-900">
        <LegendItem color="#007bff">
          Current Usage -{' '}
          {account.current_cycle_cu_quota_usage.toLocaleString()}
        </LegendItem>
        <LegendItem color="#6b7993">
          Allocated CU - {account.cu_quota_grant.toLocaleString()}
        </LegendItem>
        <LegendItem color="#252e3e">
          Predicted Usage - {predictedUsage.toLocaleString()}
        </LegendItem>
      </div>
    </GradientBorder>
  );
}
