export function Footer() {
  return (
    <footer className="border-t border-gray-1100 flex justify-between px-6 md:px-16 text-xs w-full py-6">
      <div className="flex gap-x-6 opacity-20">
        <div>&copy; {new Date().getFullYear()} Bozo Labs LLC.</div>
      </div>
      <nav className="flex gap-x-6 opacity-20">
        <a
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/QuantsyNFT"
        >
          Twitter
        </a>
        <a
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/y8Y9dm597W"
        >
          Discord
        </a>
        <a
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          href="mailto:hello@quantsy.xyz"
        >
          Email
        </a>
      </nav>
    </footer>
  );
}
