export const TheadEl: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
> = props => <thead {...props} />;

const Th: React.FC<
  React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  >
> = ({ className, ...props }) => (
  <th
    className={`${
      className ?? ''
    } first:pl-8 last:text-right last:pr-8 py-4 text-left font-bold text-xs text-gray-800 uppercase`}
    {...props}
  />
);

export const TheadTr: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
> = ({ className, ...props }) => (
  <tr className={`${className ?? ''} group`} {...props} />
);

export const Thead = {
  Thead: TheadEl,
  Th,
  Tr: TheadTr,
};

const Td: React.FC<
  React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >
> = ({ className, ...props }) => (
  <td
    className={`${
      className ?? ''
    } first:pl-8 last:text-right last:pr-8 py-4 text-left border-[#1b1d27] border-t group-last:border-b group-first:first:rounded-tl-lg group-first:last:rounded-tr-lg group-last:last:rounded-br-lg last:border-r group-last:first:rounded-bl-lg first:border-l`}
    {...props}
  />
);

const TbodyTr: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
> = ({ className, ...props }) => (
  <tr
    className={`${
      className ?? ''
    } group bg-[#171717] font-bold text-xs lg:text-sm tracking-wide`}
    {...props}
  />
);

const TbodyEl: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
> = props => <tbody {...props} />;

export const Tbody = {
  Tbody: TbodyEl,
  Tr: TbodyTr,
  Td: Td,
};

export const Table: React.FC<
  React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
> = ({ className, ...props }) => (
  <table
    className={`${className ?? ''} w-full border-separate border-spacing-0`}
    {...props}
  />
);
