import type { NextPage } from 'next';
import Link from 'next/link';

import { useActiveSessionGuard } from '@/components/useActiveSessionGuard';
import { useAccount } from '@/components/useAccount';
import { Header } from '@/components/Header';
import { ContentWrapper } from '@/components/ContentWrapper';
import { ApiAccountsRequestsTable } from '@/components/ApiAccountsRequestsTable';
import { DateGroupedApiAccountsRequestsChart } from '@/components/DateGroupedApiAccountsRequestsChart';
import { GradientBorder } from '@/components/GradientBorder';
import { Footer } from '@/components/Footer';

function NoSubscriptionView() {
  return (
    <div className="flex flex-col justify-center w-full h-full">
      <div className="flex items-center justify-center gap-x-20 w-full">
        <div className="flex flex-col items-center lg:items-start whitespace-nowrap">
          <h2 className="text-gray-600 font-bold mb-1">GET STARTED</h2>
          <h1 className="font-bold text-5xl tracking-wide mb-8">
            SELECT A PLAN
          </h1>
          <Link
            className={`text-white font-bold text-sm text-center px-6 rounded-xl transition-colors shadow-[0_0_30px_10px_rgba(71,96,255,0.5)] bg-gradient-to-r from-glow-blue to-glow-purple py-4 inline-block`}
            href="/subscription"
          >
            SUBSCRIPTIONS
          </Link>
        </div>
        <div className="hidden lg:block h-[30rem] w-full max-w-[60rem] relative pointer-events-none">
          <GradientBorder>
            <div className="flex flex-col justify-between h-full px-10 py-16 ">
              {[10000, 5000, 1000, 0].map(x => (
                <div key={x} className="flex items-baseline gap-x-2">
                  <div className="text-3xs text-gray-1000 font-bold w-10 text-right">
                    {x.toLocaleString()}
                  </div>
                  <div className="border-b border-gray-1000 w-full" />
                </div>
              ))}
              <div className="absolute bottom-10 left-28 right-10 flex justify-between text-3xs text-gray-1000 font-bold">
                {['Jul 22', 'Aug 22', 'Sep 22', 'Oct 22'].map(x => (
                  <div key={x}>{x}</div>
                ))}
              </div>
            </div>
          </GradientBorder>
        </div>
      </div>
    </div>
  );
}

function UsageProgressBar() {
  const { account } = useAccount();

  if (!account) {
    return null;
  }

  const usagePercentage =
    account.current_cycle_cu_quota_usage / account.cu_quota_grant;

  return (
    <GradientBorder
      type={usagePercentage >= 0.85 ? 'warning' : 'default'}
      fill
      fillPercentage={usagePercentage}
    >
      <div className="px-8 w-full font-bold text-xs py-2 text-white relative uppercase flex justify-between items-center">
        <div>
          {account.current_cycle_cu_quota_usage.toLocaleString()}/
          {account.cu_quota_grant.toLocaleString()} CU USED THIS CYCLE
        </div>
        {usagePercentage >= 0.85 && (
          <Link className="text-red-500" href="/subscription">
            UPGRADE PLAN
          </Link>
        )}
      </div>
    </GradientBorder>
  );
}

const Home: NextPage = () => {
  useActiveSessionGuard(true, '/sign-in');

  const { account } = useAccount();

  if (!account) {
    return null;
  }

  return (
    <div>
      <Header>
        {['incomplete', 'incomplete_expired'].includes(
          account.subscription_status,
        ) ? (
          <ContentWrapper className="absolute w-full h-full px-16">
            <NoSubscriptionView />
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-6">
                <UsageProgressBar />
                <DateGroupedApiAccountsRequestsChart />
              </div>
              <ApiAccountsRequestsTable />
            </ContentWrapper>

            <Footer />
          </>
        )}
      </Header>
    </div>
  );
};

export default Home;
