import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

import { LogoText } from '@/components/LogoText';
import { signOut } from '@/components/signOut';

const linkStyles =
  'font-bold uppercase text-sm tracking-wider rounded-full transition-colors border px-4 p-2 hover:border-gray-300 cursor-pointer outline-none';
function HeaderLink({
  className,
  isCurrent,
  ...props
}: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps & { isCurrent: boolean }) {
  return (
    <Link
      className={`${className ?? ''} ${linkStyles} ${
        isCurrent
          ? 'border-gray-1000 hover:border-gray-1000'
          : 'border-transparent'
      }`}
      {...props}
    />
  );
}

const links = [
  { href: '/', label: 'Dashboard' },
  { href: '/subscription', label: 'Subscription' },
  { href: '/keys', label: 'API Keys' },
  { href: 'https://docs.quantsy.xyz', label: 'Docs', target: '_blank' },
];

type Props = { children?: React.ReactNode };

function MobileNav() {
  const activePath = useRouter().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);

  return (
    <>
      {/* Trigger */}
      <button className="items-center lg:hidden text-xl" onClick={toggleOpen}>
        <AnimatePresence mode="wait">
          <motion.span
            initial={{ opacity: 1 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            key={isOpen ? 'true' : 'false'}
          >
            {isOpen ? <IoMdClose /> : <AiOutlineMenu />}
          </motion.span>
        </AnimatePresence>
      </button>

      {/* Backdrop */}
      <div
        onClick={isOpen ? toggleOpen : undefined}
        className={`fixed left-0 top-[3.7rem] w-full lg:hidden min-h-[calc(var(--app-height)-3.7rem)] bg-black bg-opacity-70 transition-opacity backdrop-blur z-10 ${
          isOpen ? 'opacity-1' : 'pointer-events-none opacity-0'
        }`}
      />

      {/* Links */}
      <nav
        className={`fixed flex flex-col justify-center items-start lg:hidden top-[3.7rem] min-h-[calc(var(--app-height)-3.7rem)] shadow-2xl bg-[#0a0909] z-10 pt-4 px-8 left-full transition-transform ${
          isOpen ? '-translate-x-full' : ''
        }`}
      >
        {links.map(({ href, label, target }) => (
          <HeaderLink
            key={href}
            href={href}
            isCurrent={activePath === href}
            target={target}
          >
            {label}
          </HeaderLink>
        ))}
        <a className={`${linkStyles} border-transparent`} onClick={signOut}>
          Sign Out
        </a>
      </nav>
    </>
  );
}

export function Header({ children }: Props) {
  const activePath = useRouter().pathname;

  return (
    <>
      <header className="fixed bg-[#0a0909] z-10 bg-opacity-75 backdrop-blur-md left-0 top-0 w-full flex justify-between items-center px-4 lg:px-8 py-4 border-b border-gray-1100">
        <LogoText className="w-40 lg:w-48" />
        <nav className="hidden lg:flex items-center gap-x-4">
          {links.map(({ href, label, target }) => (
            <HeaderLink
              key={href}
              href={href}
              isCurrent={activePath === href}
              target={target}
            >
              {label}
            </HeaderLink>
          ))}
          <a className={`${linkStyles} border-transparent`} onClick={signOut}>
            Sign Out
          </a>
        </nav>
        <MobileNav />
      </header>

      <div className="mt-[3.7rem] min-h-[calc(var(--app-height)-3.7rem)] lg:mt-[4.5rem] relative lg:min-h-[calc(var(--app-height)-4.5rem)]">
        {children}
      </div>
    </>
  );
}
