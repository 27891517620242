type Props = {
  children?: React.ReactNode;
  type?: 'default' | 'warning' | 'success';
  fill?: boolean;
  fillPercentage?: number;
  className?: string;
};

export function GradientBorder({
  children,
  className,
  fill = false,
  type = 'default',
  fillPercentage = 1,
}: Props) {
  return (
    <div
      className={`${className ?? ''} bg-gradient-to-r ${
        type === 'warning'
          ? 'from-[#fad126] to-[#ff544f]'
          : type === 'success'
          ? 'from-green-500 to-green-800'
          : 'from-[#3572e5] to-[#fc686f]'
      }  animate-gradient-x h-full rounded-[13px] p-[1px]`}
    >
      <div className="h-full bg-[#0a0909] rounded-xl relative overflow-hidden">
        {fill && (
          <div
            className={`absolute top-0 bottom-0 left-0 bg-gradient-to-r ${
              type === 'warning'
                ? 'from-[#fad126] to-[#ff544f]'
                : type === 'success'
                ? 'from-green-500 to-green-800'
                : 'from-[#3572e5] to-[#8442cb]'
            } opacity-20`}
            style={{ width: `${fillPercentage * 100}%` }}
          />
        )}
        {children}
      </div>
    </div>
  );
}
