import Router from 'next/router';
import { useEffect } from 'react';

import { useSessionIsActive } from '@/components/useSessionIsActive';

export const useActiveSessionGuard = (
  pred: boolean,
  redirect: string = '/404',
) => {
  const sessionIsActive = useSessionIsActive();
  useEffect(() => {
    if (sessionIsActive !== pred) {
      Router.replace(redirect);
    }
  }, [sessionIsActive, redirect, pred]);
};
