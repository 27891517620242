import { usePaginatedSWR } from '@/components/usePaginatedSWR';

type ApiAccountsRequest = {
  api_key: string;
  request_url: string;
  created_at: string;
  cu_quota_impact: number;
  api_key_name: string;
};

const limit = 10;
export const useApiAccountsRequests = () => {
  const { data, error, prev, next, totalPages, page } = usePaginatedSWR<
    ApiAccountsRequest[]
  >(`${process.env.NEXT_PUBLIC_API_URL}/api_accounts_requests`, {
    limit,
  });

  return {
    data,
    error,
    next,
    prev,
    totalPages,
    page,
  };
};
