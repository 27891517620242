type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { compact?: boolean };

export const TransparentButton: React.FC<Props> = ({
  className,
  compact,
  ...props
}) => (
  <button
    className={`bg-transparent outline-none flex flex-col justify-center items-center border uppercase border-gray-900 hover:border-white disabled:cursor-not-allowed disabled:hover:border-gray-900 disabled:opacity-50 disabled:bg-gray-700 disabled:bg-opacity-20 rounded-lg transition-colors text-white ${
      compact ? 'py-1 px-2 text-sm' : 'p-4'
    } ${className ?? ''}`}
    {...props}
  />
);
